// authentication
export const SET_LAT = "SET_LAT";
export const SET_AUTH_REQUIRED = "SET_AUTH_REQUIRED";
export const SET_FIELD = "SET_FIELD";

// qualification
export const GET_RATES_SUCCESS = "GET_RATES_SUCCESS";
export const GET_RATES_FAIL = "GET_RATES_FAIL";
export const DATA_RECIEVED_SUCCESS = "DATA_RECIEVED_SUCCESS";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const UPDATE_LOAN_ID = "UPDATE_LOAN_ID";
export const SET_ALL_RATES_ROW_DATA = "SET_ALL_RATES_ROW_DATA";

// Search
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_FAIL = "GET_SEARCH_FAIL";
export const GET_LIENPOSITION_SUCCESS = "GET_LIENPOSITION_SUCCESS";
export const GET_LIENPOSITION_FAIL = "GET_LIENPOSITION_FAIL";
export const SUBMIT_LOANDATA_SUCCESS = "SUBMIT_LOANDATA_SUCCESS";
export const UPDATE_ERRORS_LIST = "UPDATE_ERRORS_LIST";
export const UPDATE_LOANINFO = "UPDATE_LOANINFO";
export const UPDATE_ORIGINAL_LOANINFO = "UPDATE_ORIGINAL_LOANINFO";
export const GET_PAGEDATA_SUCCESS = "GET_PAGEDATA_SUCCESS";
export const GET_PAGEDATA_FAIL = "GET_PAGEDATA_FAIL";
export const GET_COUNT_PAGEDATA = "GET_COUNT_PAGEDATA";
export const SET_LOANPROPERTIES = "SET_LOANPROPERTIES";
export const SET_ORIGINALLOANINFORMATION = "SET_ORIGINALLOANINFORMATION";
export const SET_USERDATA = "SET_USERDATA";

// Summary
export const GET_SUMMARY_SUCCESS = "GET_SUMMARY_SUCCESS";
export const GET_SUMMARY_FAIL = "GET_SUMMARY_FAIL";
export const SUBMIT_RATES_SUCCESS = "SUBMIT_RATES_SUCCESS";
export const GET_UPDATE_ENCOMPASS_SUCCESS = "GET_UPDATE_ENCOMPASS_SUCCESS";
export const GET_ENCOMPASS_ELEMENTS = "GET_ENCOMPASS_ELEMENTS";
export const GET_LOOKUPS_BANKRUPTCY = "GET_LOOKUPS_BANKRUPTCY";
export const GET_LOOKUPS_FORECLOSURE = "GET_LOOKUPS_FORECLOSURE";

// Buy Side
export const SET_BUYSIDE_PROGRAMS = "@buyside/SET_SELECTED_PRODUCT";

// Error Handling
export const SWITCH_ERROR_MODAL = "SWITCH_ERROR_MODAL";

// Landing tabs
export const SET_TAB_KEY = "SET_TAB_KEY";
export const SET_REF_CONTAINER = "SET_REF_CONTAINER";

// Pricing Analyzer for Relock loan
export const GET_RELOCK_DATA_SUCCESS_ALL = "GET_RELOCK_DATA_SUCCESS_ALL";
export const GET_RELOCK_DATA_SUCCESS = "GET_RELOCK_DATA_SUCCESS";
export const UPDATE_ORIGINAL_LOAN_LOCKREQUEST =
  "UPDATE_ORIGINAL_LOAN_LOCKREQUEST";
export const UPDATE_LOAN_INFO_RELOCK_REQUEST =
  "UPDATE_LOAN_INFO_RELOCK_REQUEST";
export const UPDATE_ADJUSTMENT_DETAILS_CACHE =
  "UPDATE_ADJUSTMENT_DETAILS_CACHE";

import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { isEmpty } from "utils/shared";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataTable } from "@elliemae/ds-data-table";
import { useRateSelectorLien, useCompareDataSelector } from "data/useSelectors";
import { selectPriceAction } from "data/lockSummary/actionCreators";
import { arrayOf, shape } from "prop-types";
import Session, {
  IS_BUYSIDE,
  IS_VALIDATE,
  ALL_SUMMARY_PARAMS,
} from "services/Session";
import SlidePriceCards from "./SlidePriceCards";
import { generateAdjustments } from "./helpers";
import { loanBuySideAdjustmentsSelector } from "./selectors";
import {
  getBuysideColumns,
  defaultColumns,
  getPricingChangedColumns,
  getValidateColumns,
} from "./columns";

const PricingGrid = ({ adjustmentRows }) => {
  const tabId = useSelector(({ landing }) => landing.tabId, shallowEqual);
  const [openSlide, setOpenSlide] = useState("");
  const dispatch = useDispatch();
  const selectPrice = (newPrice) => dispatch(selectPriceAction(newPrice));
  const { selectedPrice } = useSelector(
    ({ epps: { lockSummary } }) => lockSummary
  );
  const adjustments = useRateSelectorLien("adjustments", []);
  const {
    comparissonArray,
    changeCounter: pricingChanges = 0,
  } = useCompareDataSelector();

  const isSummaryTab = tabId === "/lock-summary";
  const pricingHasChanged =
    isSummaryTab &&
    pricingChanges !== 0 &&
    isEmpty(Session.get(ALL_SUMMARY_PARAMS));
  const loanVariations = useSelector(loanBuySideAdjustmentsSelector());
  const isBuyside = Session.get(IS_BUYSIDE);
  const isValidate = Session.get(IS_VALIDATE);
  const rowsBeforeEdit = useRef({});
  const memoRows = useMemo(
    () =>
      adjustmentRows.length
        ? adjustmentRows
        : generateAdjustments(
            pricingHasChanged ? comparissonArray : adjustments,
            loanVariations,
            isBuyside,
            rowsBeforeEdit.current,
            isValidate,
            pricingHasChanged
          ),
    [adjustments, loanVariations, adjustmentRows, pricingHasChanged]
  );

  useEffect(() => {
    rowsBeforeEdit.current = memoRows;
  }, []);

  // TODO: Remove when dimsum allows dynamic columns
  const [key, setKey] = useState(0);
  const memoColumns = useMemo(() => {
    setKey((prev) => prev + 1);
    if (isBuyside) {
      if (pricingHasChanged) {
        if (isValidate) {
          return getValidateColumns({ onEditClick: setOpenSlide });
        }
        return getPricingChangedColumns({
          onEditClick: setOpenSlide,
          onSelectPrice: selectPrice,
          selectedPrice,
        });
      }
      return getBuysideColumns({ onEditClick: setOpenSlide });
    }
    return defaultColumns;
  }, [isBuyside, isValidate, pricingHasChanged, selectedPrice]);

  return (
    <>
      {openSlide && <SlidePriceCards open={openSlide} setOpen={setOpenSlide} />}
      <div
        style={{
          width: "100%",
          alignItems: "center",
        }}
      >
        {(adjustments.length > 0 || adjustmentRows.length > 0) && (
          <DataTable
            data-testid="PricingGrid-all-components"
            columns={memoColumns || []}
            data={memoRows || []}
            colsLayoutStyle="auto"
            uniqueRowAccessor="id"
            selectable
            selection={
              memoRows.length && { [memoRows[memoRows.length - 1].id]: true }
            }
            noSelectionColumn
            textWrap="wrap-all"
            key={key}
            {...(pricingHasChanged
              ? {
                  getRowVariant: (row) => {
                    if (
                      row.original.price !== row.original.currentPrice ||
                      row.original.rate !== row.original.currentRate
                    ) {
                      return "ds-secondary-row";
                    }
                    return "ds-primary-row";
                  },
                }
              : {})}
          />
        )}
      </div>
    </>
  );
};

PricingGrid.defaultProps = { adjustmentRows: [] };
PricingGrid.propTypes = {
  adjustmentRows: arrayOf(shape({})),
};

export default memo(PricingGrid);

import {
  GET_RATES_SUCCESS,
  UPDATE_LOANINFO,
  UPDATE_ERRORS_LIST,
  GET_FILTERS_SUCCESS,
  UPDATE_LOAN_ID,
  UPDATE_ORIGINAL_LOANINFO,
  GET_ENCOMPASS_ELEMENTS,
  SWITCH_ERROR_MODAL,
  GET_COUNT_PAGEDATA,
  SET_TAB_KEY,
  SET_ALL_RATES_ROW_DATA,
  UPDATE_ORIGINAL_LOAN_LOCKREQUEST,
  SET_LOANPROPERTIES,
  SET_ORIGINALLOANINFORMATION,
  SET_USERDATA,
  UPDATE_LOAN_INFO_RELOCK_REQUEST,
  UPDATE_ADJUSTMENT_DETAILS_CACHE,
} from "common/constants/ActionConstants";
import getSavedState from "utils/getSavedState";
import rates from "Qualification/QualificationReducer";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { calculateTotalLoanAmount } from "view/SearchForm/components/LoanInformationForm/utils/helpers";

const INITIAL_STATE = getSavedState("EPPS", {});

export default function EPPS(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_RATES_SUCCESS:
      return {
        ...state,
        loanPrograms: rates(state.loanPrograms, action),
      };
    case UPDATE_LOAN_ID:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanInformation: {
            ...state.loanInfo.loanInformation,
            loanId: rates(state.loanInfo.loanInformation, action),
          },
        },
      };
    case GET_ENCOMPASS_ELEMENTS:
      return {
        ...state,
        // encompassElements: summary(state.encompassElements, action),
      };
    case GET_COUNT_PAGEDATA:
      return {
        ...state,
        errorPageData: action.payload,
      };
    case GET_FILTERS_SUCCESS:
      return {
        ...state,
        filters: rates(state.filters, action),
      };
    case UPDATE_LOANINFO: {
      // TODO all these changes should go in the action, not the reducer
      let newBorrowers = action.payload.borrowers;
      const { target, targetLabel } = action.payload.loanInformation;
      const { loanInformation } = action.payload;

      if (!isEmpty(newBorrowers)) {
        newBorrowers = newBorrowers.map((borrower) => {
          const newBorrower = { ...borrower };
          if (!newBorrower.id) newBorrower.id = uuidv4();
          return newBorrower;
        });
      }
      let newOtherRealEstate = action.payload.property.otherRealEstateOwned;
      if (!isEmpty(newOtherRealEstate)) {
        newOtherRealEstate = newOtherRealEstate.map((estate) => {
          const newEstate = { ...estate };
          if (!newEstate.id) newEstate.id = uuidv4();
          return newEstate;
        });
      }

      loanInformation.totalMortgageAmount = calculateTotalLoanAmount(
        loanInformation.firstMortgageAmount,
        loanInformation.financedAmount
      );

      if (!targetLabel) {
        if (target > 80) {
          loanInformation.targetLabel = "Price";
        } else {
          loanInformation.targetLabel = "Rate";
        }
      }

      return {
        ...state,
        loanInfo: {
          ...action.payload,
          loanInformation: {
            ...loanInformation,
          },
          borrowers: newBorrowers,
          property: {
            ...action.payload.property,
            otherRealEstateOwned: newOtherRealEstate,
          },
        },
      };
    }
    case "UPDATE_TARGET_LOAN_DATA":
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          loanInformation: {
            ...state.loanInfo.loanInformation,
            target: action.payload.value,
            targetLabel: action.payload.label,
          },
        },
      };
    case SET_LOANPROPERTIES:
      return {
        ...state,
        loanProperties: action.payload,
      };
    case SET_ORIGINALLOANINFORMATION:
      return {
        ...state,
        originalLoanInformation: action.payload,
      };
    case UPDATE_ORIGINAL_LOANINFO:
      return {
        ...state,
        originalLoanInfo: action.payload,
      };
    case UPDATE_ERRORS_LIST:
      return {
        ...state,
        errors: action.payload || [],
      };
    case SWITCH_ERROR_MODAL:
      return {
        ...state,
        showGlobalErrorModal: action.payload.showErrorModal,
        globalErrorModalTitle: action.payload.title,
        globalErrorModalMessage: action.payload.errorMessage,
        globalErrorModalOnClose: action.payload.onClose,
      };
    case SET_TAB_KEY:
      return {
        ...state,
        tabs: action.payload,
      };
    case SET_ALL_RATES_ROW_DATA:
      return { ...state, allRatesRowData: action.payload };
    case UPDATE_ORIGINAL_LOAN_LOCKREQUEST:
      return {
        ...state,
        originalLoanLockRequest: action.payload,
      };
    case UPDATE_ADJUSTMENT_DETAILS_CACHE:
      return {
        ...state,
        adjustmentDetailsCache: action.payload,
      };
    case SET_USERDATA:
      return {
        ...state,
        userData: action.payload,
      };
    case UPDATE_LOAN_INFO_RELOCK_REQUEST:
      return {
        ...state,
        loanInfo: {
          ...state.loanInfo,
          relockRequest: {
            ...state.loanInfo.relockRequest,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
}

import { DSInputText } from "@elliemae/ds-form-input-text";
import { BUTTON_VARIANT } from "@elliemae/ds-button";
import { DSControlledLargeTextInput } from "@elliemae/ds-form-input-textarea";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { Grid } from "@elliemae/ds-grid";
import { DSSeparatorV2 } from "@elliemae/ds-separator";
import FooterButtons from "components/FooterButtons";
import { closeLoader } from "data/screenLoader/actions";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import { AdjustmentTable } from "./adjustmentTable";

const getLockData = () => {
  return Promise.resolve({
    rateSheetID: "123123",
    lockDate: new Date(),
    lockDays: 30,
    lockExpirationDate: new Date(),
    totalRateRequested: 3.5,
    rateAdjustments: [
      { description: "AA new rule", amount: 0.25 },
      { description: "AA new rule 2", amount: 0.25 },
      { description: "AA new rule 3", amount: 0.25 },
    ],
    priceAdjustments: [
      { description: "Price adjustment 1", amount: 0.25 },
      { description: "Price adjustment 2", amount: 0.25 },
      { description: "Price adjustment 3", amount: 0.25 },
      { description: "Price adjustment 4", amount: 0.25 },
      { description: "Price adjustment 5", amount: 0.25 },
      { description: "Price adjustment 6", amount: 0.25 },
    ],
    totalPriceRequested: 99.635,
    baseRate: 4.125,
    basePrice: 97.935,
  });
};

const LockExtension = () => {
  const lockDayOptions = [30, 45, 60, 90, 120].map((i) => ({
    dsId: `optionId${i}`,
    type: "option",
    value: `${i}`,
    label: `${i} days`,
  })); // TODO: do these need to come from some service?
  const [extensionLockDays, setExtensionLockDays] = useState(lockDayOptions[0]);
  const [comments, setComments] = useState("");
  const [lockDetails, setLockDetails] = useState({
    rateSheetID: "",
    lockDate: "",
    lockDays: "",
    lockExpirationDate: "",
    totalRateRequested: "",
    rateAdjustments: [],
    priceAdjustments: [],
    totalPriceRequested: "",
    baseRate: "",
    basePrice: "",
  });
  const footerButtons = [
    {
      id: "cancel",
      type: "secondary",
      text: "Cancel",
      variant: BUTTON_VARIANT.DEFAULT,
      disabled: false,
    },
    {
      id: "request-extension",
      type: "primary",
      text: "Request Extension",
      variant: BUTTON_VARIANT.DEFAULT,
      disabled: false,
      size: "m",
    },
  ];
  const dispatch = useDispatch();

  const initPage = async () => {
    dispatch(closeLoader());
    setLockDetails(await getLockData());
  };

  const currentLockDetails = useMemo(() => {
    return [
      { label: "Rate Sheet ID", value: lockDetails.rateSheetID },
      { label: "Lock Date", value: lockDetails.lockDate?.toString() },
      { label: "Lock # Days", value: lockDetails.lockDays },
      {
        label: "Lock Expiration Date",
        value: lockDetails.lockExpirationDate?.toString(),
      },
    ];
  }, [lockDetails]);

  const extensionDetails = useMemo(() => {
    return [
      {
        label: "Current Lock Expiration Date",
        value: lockDetails.lockExpirationDate?.toString(),
      },
      {
        label: "New Lock Expiration Date",
        value: lockDetails.lockExpirationDate?.toString(),
      }, // TODO: plus the value in the days to extend, computed with Elli's date library
      { label: "Price Adjustments", value: "" }, // TODO: not sure what goes here
    ];
  }, [lockDetails]);

  const rateTableData = useMemo(() => {
    return {
      rate: {
        adjustments: lockDetails.rateAdjustments,
        typeLabel: "Rate",
        totalRequested: 6.625,
        total: 2.5,
        base: 4.125,
      },
      price: {
        adjustments: lockDetails.rateAdjustments,
        typeLabel: "Price",
        totalRequested: 6.625,
        total: 2.5,
        base: 4.125,
      },
    };
  }, [lockDetails]);

  useEffect(() => {
    initPage();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StyledEppsParentView>
        <StyledEppsContainerBody>
          <StyledEppsWrapper>
            <div>
              <h3>Current Lock Details</h3>
            </div>

            <Grid>
              <Grid
                gutter="m"
                cols={[`repeat(${currentLockDetails.length}, auto)`, "1fr"]}
              >
                {currentLockDetails.map(({ label, value }) => (
                  <DSFormLayoutBlockItem
                    fitContent
                    label={label}
                    inputID="readonly"
                  >
                    <DSInputText id="readonly" value={value} readOnly />
                  </DSFormLayoutBlockItem>
                ))}
              </Grid>
            </Grid>

            <DSSeparatorV2 m="m 0" isDotted />

            <Grid>
              <Grid
                cols={{
                  small: [1 / 1, 0, 1 / 1],
                  medium: ["1fr", "auto", "1fr"],
                }}
              >
                {rateTableData && <AdjustmentTable {...rateTableData.rate} />}
                <DSSeparatorV2 m="0 s" isVertical isDotted />
                {rateTableData && <AdjustmentTable {...rateTableData.price} />}
              </Grid>
            </Grid>

            <DSSeparatorV2 m="m 0" />

            <h3>Extension Information</h3>

            <Grid>
              <Grid
                cols={{
                  small: [1 / 1, 0, 1 / 1],
                  medium: ["275px", "auto", "1fr"],
                }}
              >
                <div>
                  {extensionDetails.map(({ label, value }) => (
                    <DSFormLayoutBlockItem
                      m="0 0 s 0"
                      fitContent
                      label={label}
                      inputID="readonly"
                    >
                      <DSInputText id="readonly" value={value} readOnly />
                    </DSFormLayoutBlockItem>
                  ))}
                </div>
                <DSSeparatorV2 m="0 s" isDotted isVertical />

                <Grid>
                  <Grid cols={{ small: ["1fr"], medium: ["300px"] }}>
                    <div>
                      <DSFormLayoutBlockItem
                        label="Days to Extend"
                        inputID="daysToExtend"
                        autocomplete="off"
                      >
                        <DSSingleComboBox
                          allOptions={lockDayOptions}
                          onChange={setExtensionLockDays}
                          selectedValue={extensionLockDays}
                          id="daysToExtend"
                        />
                      </DSFormLayoutBlockItem>
                    </div>
                  </Grid>

                  <div>
                    <DSFormLayoutBlockItem
                      inputID="comments"
                      maxCharCounter={200}
                      currentChar={comments.length}
                      label="Comments"
                      hasError={comments.length >= 200}
                      validationMessage="Max characters reached."
                    >
                      <DSControlledLargeTextInput
                        onChange={setComments}
                        maxHeight={100}
                        value={comments}
                        id="commentsInput"
                        hasError={comments.length >= 200}
                      />
                    </DSFormLayoutBlockItem>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <FooterButtons buttons={footerButtons} />
            </Grid>
          </StyledEppsWrapper>
        </StyledEppsContainerBody>
      </StyledEppsParentView>
    </>
  );
};

export default LockExtension;

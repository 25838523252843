import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { transformRateSelectorDataToSlide } from "view/SearchResult/components/GridPriceDetails/helpers";
import GridPriceDetails from "../../../SearchResult/components/GridPriceDetails/GridPriceDetails";
import PricingTableStyled from "./styled";

function PricingTable() {
  const {
    lien: { adjustments = [], armIndexValue = null, arm = false } = {},
    loan: { mortgageType = "" } = {},
  } = useSelector(({ epps }) => epps.lockSummary.rateSelector || {});
  const heloc = useMemo(() => mortgageType === "HELOC", [mortgageType]);
  return (
    <PricingTableStyled
      fullWidth={arm || heloc}
      data-testid="PricingTable-all-components"
    >
      <GridPriceDetails
        data={{
          ...transformRateSelectorDataToSlide(adjustments, armIndexValue),
          showMarginTable: arm || heloc,
        }}
        showText={false}
      />
    </PricingTableStyled>
  );
}

export default PricingTable;

import React, { useEffect, memo } from "react";
import { bool, element, node, string } from "prop-types";
import { DSModalSlide } from "@elliemae/ds-modal-slide";

const ModalSlide = ({ isOpen, header, footer, children, maxWidth }) => {
  const { style } = document.querySelector(".root-container");
  const rootElement = document.getElementById("root");

  const changeModalWidth = () => {
    const modalContent = rootElement.querySelector(
      ".em-ds-modal-slide__content"
    );
    if (modalContent) {
      if (maxWidth) {
        modalContent.style.maxWidth = `${maxWidth}`;
      } else {
        modalContent.style.maxWidth = "522px";
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      style.cssText += "overflow-y: hidden";
    } else {
      style.removeProperty("overflow-y");
    }
    return () => style.removeProperty("overflow-y");
  }, [isOpen]);

  return (
    <DSModalSlide
      fadeIn={300}
      fadeOut={100}
      fullWidth={false}
      getContainer={() => {
        changeModalWidth();
        return rootElement;
      }}
      isOpen={isOpen}
      header={header}
      footer={footer}
      data-testid="ModalSlide-all-components"
    >
      {children}
    </DSModalSlide>
  );
};

ModalSlide.defaultProps = {
  isOpen: false,
  footer: null,
  header: null,
  maxWidth: null,
};

ModalSlide.propTypes = {
  isOpen: bool,
  header: element,
  footer: element,
  children: node.isRequired,
  maxWidth: string,
};

export default memo(ModalSlide);

import React from "react";
import { Grid } from "@elliemae/ds-grid";
import { getDefaultTheme } from "@elliemae/pui-theme";
import PropTypes from "prop-types";
import { DSTypography, TYPOGRAPHY_VARIANTS } from "@elliemae/ds-typography";

const theme = getDefaultTheme();

const AdjustmentRowText = ({ text, isHeader }) => (
  <>
    {isHeader ? (
      <DSTypography variant={TYPOGRAPHY_VARIANTS.B1} color="neutral-500">
        <b>{text}</b>
      </DSTypography>
    ) : (
      text
    )}
  </>
);

AdjustmentRowText.propTypes = {
  text: PropTypes.string.isRequired,
  isHeader: PropTypes.bool.isRequired,
};

const AdjustmentRowItem = ({ text1, text2, isHeader = false, gridProps }) => (
  <>
    <Grid gutter="m" cols={["1fr", "1fr"]} {...gridProps}>
      <Grid m="xxs" justifyContent="flex-start">
        <AdjustmentRowText text={text1} isHeader={isHeader} />
      </Grid>
      <Grid m="xxs" justifyContent="flex-end">
        <AdjustmentRowText text={text2} isHeader={isHeader} />
      </Grid>
    </Grid>
  </>
);

AdjustmentRowItem.propTypes = {
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  isHeader: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  gridProps: PropTypes.object,
};

AdjustmentRowItem.defaultProps = {
  isHeader: false,
  gridProps: {},
};

export const AdjustmentTable = (props) => {
  const { adjustments, typeLabel, totalRequested, total, base } = props;
  const border = "1px solid neutral-100";
  return (
    <div>
      {[
        { text1: `Total ${typeLabel} Requested`, text2: totalRequested },
        {
          isHeader: true,
          text1: `${typeLabel} Adjustment`,
          text2: "Amount",
          gridProps: { bg: theme.colors.neutral[100], border },
        },
        ...adjustments.map((adj) => ({
          text1: adj.description,
          text2: adj.amount,
          gridProps: { border },
        })),
        { text1: `Total ${typeLabel} Requested`, text2: total },
        {
          isHeader: true,
          text1: "Base Rate",
          text2: base,
          gridProps: {
            bg: theme.colors.brand[100],
            borderTop: border,
          },
        },
        [],
      ].map((params) => AdjustmentRowItem(params))}
    </div>
  );
};

AdjustmentTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  adjustments: PropTypes.array.isRequired,
  typeLabel: PropTypes.string.isRequired,
  totalRequested: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  base: PropTypes.number.isRequired,
};

export const pause = (time) =>
  new Promise((res) => setTimeout(() => res(true), time));

export const lookupKeyFromValue = (enumeratedType, value) => {
  return Object.entries(enumeratedType).find(([, v]) => v === value)?.[0];
};

export const formatDate = (date, formatType) => {
  const config = {
    Calendar: () => {
      return [
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
    },
  };

  return config[formatType]?.() ?? date;
};

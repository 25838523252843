import React from "react";
import { bool } from "prop-types";
import { DSTooltipV2, tooltipPositions } from "@elliemae/ds-tooltip";
import { InfoCircle } from "@elliemae/ds-icons";

const UpdateLockDisabledTooltip = ({ show }) => {
  if (!show) {
    return null;
  }
  return (
    <DSTooltipV2
      title={
        <div style={{ textAlign: "left", width: "15em" }}>
          Update Lock button is disabled because there are currently no changes.
        </div>
      }
      zIndex={999}
      placement={tooltipPositions.TOP}
      triggerComponent={
        <InfoCircle
          color={["brand-primary", 600]}
          data-testid="update-lock-disabled-tooltip"
        />
      }
    />
  );
};

UpdateLockDisabledTooltip.propTypes = {
  show: bool,
};

UpdateLockDisabledTooltip.defaultProps = {
  show: false,
};

export default UpdateLockDisabledTooltip;

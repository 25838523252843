import React, { useCallback, useEffect, useRef, useState } from "react";
import { DSAccordion, DSAccordionItem } from "@elliemae/ds-accordion";
import { setLoanDataValue } from "store/searchForm/actions";
import { useDispatch } from "react-redux";
import {
  ComboBoxForm,
  InputMaskForm,
  ControlledCheckboxForm,
} from "components/Form";
import { Grid } from "@elliemae/ds-grid";
import { DSHeader } from "@elliemae/ds-header";
import { DSSeparator } from "@elliemae/ds-separator";
import GridForm from "components/Form/GridForm";
import { searchFormActions } from "store/searchForm";
import Rights from "common/services/Rights";
import Session, {
  IS_BUYSIDE,
  IS_SELLSIDE,
  IS_VALIDATE,
} from "services/Session";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { useLookupOptions } from "components/Form/utils/customHooks";
import { useLoanDataFieldSelector } from "data/useSelectors";
import EffectiveDate from "./components/EffectiveDate";
import * as validations from "./utils/validations";
import * as masks from "../../utils/constants";
import * as dependencies from "./utils/dependencies";
import * as values from "./utils/values";
import {
  isFirstMortgage,
  isSecondMortgage,
  isSubordinateHELOC,
  getLoanAmountLabel,
  getPropertyValueLabel,
  handleTotalAmountValue,
  isFirstMortgageAndHeloc,
  getFloredValueTextInput,
  isHCLTVHidden,
  getCheckboxesSpan,
} from "./utils/helpers";
const LoanInformationForm = () => {
  const dispatch = useDispatch();
  const ltvRef = useRef();
  const ref = useRef(null);
  const [active, setActive] = useState(null);
  const loanOfficerId = useLoanDataFieldSelector(
    "loanInformation.loanOfficerId"
  );
  const eppsUserName = useLoanDataFieldSelector("eppsUserName");
  const officersOptions = useLookupOptions("transformedOfficeUsers");
  const loanofficerInitialValue = loanOfficerId
    ? officersOptions.find((option) => option.value === loanOfficerId)
    : null;
  const selectedOfficerValue = eppsUserName
    ? officersOptions.find((option) => option.value === eppsUserName)
    : null;
  const [loanOfficer, setLoanOfficer] = useState(
    selectedOfficerValue ?? loanofficerInitialValue
  );

  const handleSetLoanDataValue = ({ value, pathValue }) => {
    const parseValue = ["", undefined].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue));
  };

  const handleSetLoanOfficerDataValue = useCallback(
    (newValue, value, login) => {
      const parseValue = ["", undefined].includes(value) ? null : value;
      const parseLogin = ["", undefined].includes(login) ? null : login;
      dispatch(setLoanDataValue(parseValue, "loanInformation.loanOfficerId"));
      dispatch(setLoanDataValue(parseLogin, "eppsUserName"));
      setLoanOfficer(newValue);
    },
    []
  );
  const isBuyside = !Session.get(IS_BUYSIDE) || null;
  const isSellside = !Session.get(IS_SELLSIDE) || null;
  const isValidate = !Session.get(IS_VALIDATE) || null;
  const showLoanOfficer =
    Rights.AssignLoanOfficer && (isBuyside || isSellside || isValidate);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <DSHeader
        text="General Loan Information"
        fontSize={16}
        fontWeight="bold"
        lineHeight={1}
        mt="xs"
        mb="xxs"
      />

      <Grid cols={10} gutter="xs" alignItems="flex-start">
        <Grid span={2}>
          <EffectiveDate handleSetLoanDataValue={handleSetLoanDataValue} />
        </Grid>
        {showLoanOfficer ? (
          <>
            {windowWidth < 1400 && <Grid span={2} />}
            <Grid span={2}>
              <DSFormLayoutBlockItem label="Loan Officer" inputID="loanOfficer">
                <DSSingleComboBox
                  placeholder="Select Loan Officer"
                  selectedValue={loanOfficer}
                  aria-required="true"
                  id="loanOfficer"
                  innerRef={ref}
                  onChange={(data) => {
                    handleSetLoanOfficerDataValue(
                      data,
                      data ? data.value : "",
                      data ? data.login : ""
                    );
                  }}
                  allOptions={officersOptions || []}
                />
              </DSFormLayoutBlockItem>
            </Grid>
            <Grid span={windowWidth < 1400 ? 4 : 6} />
          </>
        ) : (
          <Grid span={8} />
        )}
        <Grid span={2}>
          <ComboBoxForm
            required
            isNonClearable
            name="lienPos"
            label="Lien Position"
            pathValue="loanInformation.lienPosition"
            onChangeV2={handleSetLoanDataValue}
          />
        </Grid>
        <Grid span={2}>
          <ComboBoxForm
            required
            isNonClearable
            name="loanUsage"
            label="Loan Purpose"
            pathValue="loanInformation.loanPurpose"
            onChangeV2={(data) => {
              handleSetLoanDataValue(data);
              dispatch(searchFormActions.setIsRefinance(data));
            }}
          />
        </Grid>
        <Grid span={2}>
          <InputMaskForm
            required
            clearable
            maxLength={16}
            maskOpts={masks.NOT_DECIMAL_PLACES}
            placeholder="$0.00"
            name="propertyValue"
            pathValue="property.value"
            label={getPropertyValueLabel}
            onKeyUp={handleSetLoanDataValue}
            dependencies={dependencies.PROPERTY_VALUE}
            customParser={getFloredValueTextInput}
          />
        </Grid>
        <Grid span={2}>
          <InputMaskForm
            required
            clearable
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_DECIMAL_PLACES}
            name="firstMortgageAmount"
            pathValue="loanInformation.firstMortgageAmount"
            label={getLoanAmountLabel}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.FIRST_MORTGAGE_AMOUNT}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </Grid>
        {/* Second mortgage fields */}
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            clearable
            maxLength={12}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="totSubClosedEndMort"
            pathValue="loanInformation.totSubClosedEndMort"
            label="Other Closed End 2nd Mortgage"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            clearable
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_DECIMAL_PLACES}
            name="otherPayment"
            pathValue="loanInformation.otherPayment"
            label="Monthly Housing Payment"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
            dependencies={dependencies.OTHER_PAYMENT}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.TWELVE_INTEGERS_NO_DECIMAL_PLACES}
            name="secondMortgageAmount"
            pathValue="loanInformation.secondMortgageAmount"
            label="Loan Amount/Initial Draw"
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.LOAN_AMOUNT_INITIAL_DRAW}
          />
        </GridForm>
        {/* Second mortgage fields AND Fisrt mortgage with a subordinate HELOC */}
        <GridForm
          span={2}
          hidden={isSubordinateHELOC}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_SUBORDINATE_HELOC}
        >
          <InputMaskForm
            readOnly
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="otherHELOCDraws"
            pathValue="loanInformation.otherHELOCDraws"
            label="Other HELOC Draws"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
          />
        </GridForm>
        {/* Second mortgage fields */}
        <GridForm
          span={2}
          hidden={isFirstMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            required
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="loanAmountMaxLine"
            pathValue="loanInformation.loanAmountMaxLine"
            label="Max Loan Amount/Max Line"
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            dependencies={dependencies.LOAN_AMOUNT_MAX_LIEN}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </GridForm>
        {/* Second mortgage fields AND Fisrt mortgage with a subordinate HELOC */}
        <GridForm
          span={2}
          hidden={isSubordinateHELOC}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_SUBORDINATE_HELOC}
        >
          <InputMaskForm
            readOnly
            clearable
            maxLength={16}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="otherHELOCLines"
            pathValue="loanInformation.otherHELOCLines"
            label="Other HELOC Lines"
            onBlur={handleSetLoanDataValue}
            onClearField={handleSetLoanDataValue}
          />
        </GridForm>
        {/* first mortgage fields */}
        <GridForm
          span={2}
          hidden={isSecondMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_NEGATIVE_VALUE_EIGHT_DIGITS}
            name="financedAmount"
            pathValue="loanInformation.financedAmount"
            label="PMI, MIP Financed"
            customParser={getFloredValueTextInput}
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
          />
        </GridForm>
        <GridForm
          span={2}
          hidden={isSecondMortgage}
          dependencies={dependencies.LIEN_POSITION}
        >
          <InputMaskForm
            readOnly
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.DECIMAL_PLACES}
            pathValue="loanInformation.totalMortgageAmount"
            customValue={({ customDependencies, pathValue }) => {
              const value = handleTotalAmountValue({ customDependencies });
              handleSetLoanDataValue({ value, pathValue });
              return value;
            }}
            name="totalMortgageAmount"
            label="Total Loan Amount"
            dependencies={dependencies.TOTAL_LOAN_AMOUNT}
          />
        </GridForm>
        {/* first mortgage and isHeloc field */}
        <GridForm
          span={2}
          hidden={isFirstMortgageAndHeloc}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <InputMaskForm
            required
            clearable
            maxLength={12}
            placeholder="$0.00"
            maskOpts={masks.DECIMAL_PLACES}
            name="loanAmountMaxLine"
            pathValue="loanInformation.loanAmountMaxLine"
            onClearField={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            onKeyUp={(data) => {
              ltvRef.current = false;
              handleSetLoanDataValue(data);
            }}
            label="Max Loan Amount/Max Line"
            dependencies={dependencies.LOAN_AMOUNT_MAX_LIEN}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </GridForm>
        <Grid span={10}>
          <DSSeparator
            dashed
            margin="none"
            orientation="horizontal"
            position="initial"
          />
        </Grid>
        <Grid span={10}>
          <DSAccordion activeValue={active} onChange={setActive}>
            <DSAccordionItem
              title={
                active === 0 ? "Hide Detailed Entry" : "Open Detailed Entry"
              }
              key={0}
              value={0}
            >
              <Grid cols={["repeat(8, 0.5fr)"]} gutter="xs">
                <GridForm
                  span={2}
                  hidden={isSecondMortgage}
                  dependencies={dependencies.LIEN_POSITION}
                >
                  <InputMaskForm
                    clearable
                    maxLength={16}
                    placeholder="$0.00"
                    secondMortgageAmountField
                    name="secondMortgageAmount"
                    label="2nd Mortgage Balance"
                    maskOpts={masks.DECIMAL_PLACES}
                    onKeyUp={handleSetLoanDataValue}
                    onClearField={handleSetLoanDataValue}
                    readOnly={validations.handleIsSecondMortgageAmount}
                    pathValue="loanInformation.secondMortgageAmount"
                    dependencies={dependencies.SECOND_MORTGAGE_BALANCE}
                    extraDependencies={
                      dependencies.EXTRA_SECOND_MORTGAGE_AMOUNT
                    }
                  />
                </GridForm>

                <GridForm
                  span={2}
                  hidden={isSecondMortgage}
                  dependencies={dependencies.LIEN_POSITION}
                >
                  <InputMaskForm
                    clearable
                    maxLength={12}
                    placeholder="$0"
                    maskOpts={masks.NOT_DECIMAL_PLACES}
                    name="otherPayment"
                    label="2nd Mortgage Payment"
                    onKeyUp={handleSetLoanDataValue}
                    onClearField={handleSetLoanDataValue}
                    pathValue="loanInformation.otherPayment"
                    dependencies={dependencies.SECOND_MORTGAGE_AMOUNT}
                  />
                </GridForm>

                <GridForm
                  span={2}
                  hidden={validations.isHideCommitmentAndDeliveryTpe}
                  originalLoanInfoDependencies={dependencies.CHANNEL_INFO}
                >
                  <ComboBoxForm
                    required
                    isNonClearable
                    name="commitmentTypes"
                    label="Commitment Type"
                    pathValue="loanInformation.commitmentType"
                    placeholder="Select Commitment Type"
                    onChangeV2={handleSetLoanDataValue}
                    dependencies="loanInformation.loanChannel"
                  />
                </GridForm>
                <GridForm
                  span={2}
                  hidden={validations.isHideCommitmentAndDeliveryTpe}
                  originalLoanInfoDependencies={dependencies.CHANNEL_INFO}
                >
                  <ComboBoxForm
                    required
                    isNonClearable
                    name="deliveryTypes"
                    label="Delivery Type"
                    pathValue="deliveryType"
                    placeholder="Select Delivery Type"
                    customValue={({ value, customDependencies }) => {
                      const { commitmentType } = customDependencies;
                      let initValue = value;
                      if (commitmentType === 1 || commitmentType === 2) {
                        initValue = commitmentType;
                        handleSetLoanDataValue({
                          value: initValue,
                          pathValue: "deliveryType",
                        });
                      }
                      return initValue;
                    }}
                    customDisabled
                    initialValue={0}
                    onChangeV2={handleSetLoanDataValue}
                    dependencies={dependencies.DELIVERY_TYPE}
                  />
                </GridForm>
              </Grid>
            </DSAccordionItem>
          </DSAccordion>
        </Grid>
        <Grid span={1}>
          <InputMaskForm
            name="LTV"
            label="LTV"
            useSubfix="%"
            maxLength={12}
            placeholder="0%"
            maskType="PERCENT"
            readOnly={isSecondMortgage}
            customValue={(data) => {
              if (!ltvRef.current) return values.ltvValue(data);
              return data.value;
            }}
            onBlur={(data) => {
              const { customDependencies } = data;
              const { isHELOC } = customDependencies;
              const getLoanValue = values.loanAmountValue(data);
              const getLoanMaxValue = values.calculateLoanAmountMaxLine(data);
              handleSetLoanDataValue(data);
              ltvRef.current = true;
              if (isHELOC) {
                handleSetLoanDataValue({
                  value: getLoanMaxValue,
                  pathValue: "loanInformation.loanAmountMaxLine",
                });
              } else {
                handleSetLoanDataValue({
                  value: getLoanValue,
                  pathValue: "loanInformation.firstMortgageAmount",
                });
              }
            }}
            pathValue="loanInformation.ltv"
            maskOpts={masks.LTV_PERCENT}
            dependencies={dependencies.LTV}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
          />
        </Grid>
        <Grid span={1}>
          <InputMaskForm
            readOnly
            name="CLTV"
            label="CLTV"
            useSubfix="%"
            maxLength={12}
            maskType="PERCENT"
            placeholder="0.000%"
            maskOpts={masks.PERCENT_VALUES}
            customValue={values.cltvValue}
            dependencies={dependencies.CLTV}
            extraDependencies={dependencies.EXTRA_CLTV}
          />
        </Grid>
        <GridForm
          span={1}
          hidden={isHCLTVHidden}
          dependencies={dependencies.LIEN_POSITION}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <InputMaskForm
            readOnly
            clearable
            name="HCLTV"
            useSubfix="%"
            maxLength={12}
            placeholder="0%"
            maskType="PERCENT"
            maskOpts={masks.PERCENT_VALUES}
            customValue={values.hcltvValue}
            dependencies={dependencies.HCLTV}
            extraDependencies={dependencies.EXTRA_IS_HELOC}
            label="HCLTV"
          />
        </GridForm>
        <GridForm
          span={1}
          hidden={validations.isCashOutRefinance}
          dependencies={dependencies.LOAN_PURPOSE}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <InputMaskForm
            required
            name="cashOut"
            maxLength={12}
            placeholder="$0"
            maskOpts={masks.NOT_DECIMAL_PLACES}
            pathValue="loanInformation.cashOut"
            onBlur={handleSetLoanDataValue}
            label="Cash Out"
          />
        </GridForm>
        <GridForm
          mt="2px"
          gutter="xs"
          height="100%"
          wrap="nowrap"
          alignItems="center"
          justifyContent="start"
          span={getCheckboxesSpan}
          cols={["auto", "auto", "auto", "auto"]}
          dependencies={[
            ...dependencies.LIEN_POSITION,
            ...dependencies.LOAN_PURPOSE,
          ]}
          extraDependencies={dependencies.EXTRA_IS_HELOC}
        >
          <ControlledCheckboxForm
            label="Community/Affordable Second"
            name="communityAffordableSecond"
            pathValue="loanInformation.communityAffordableSecond"
            onChange={handleSetLoanDataValue}
          />
          <ControlledCheckboxForm
            label="No Closing Costs"
            name="noClosingCost"
            pathValue="loanInformation.noClosingCost"
            onChange={handleSetLoanDataValue}
          />
          <ControlledCheckboxForm
            label="Lender Fee Waiver"
            name="lenderFeeWaiver"
            pathValue="loanInformation.lenderFeeWaiver"
            onChange={handleSetLoanDataValue}
          />
          <GridForm
            hidden={validations.isRefinanceType}
            extraDependencies={dependencies.EXTRA_REFINANCE_TYPE}
          >
            <ControlledCheckboxForm
              label="Closing Costs are included in the Loan Amount"
              name="includeClosingCost"
              pathValue="loanInformation.includeClosingCost"
              onChange={handleSetLoanDataValue}
            />
          </GridForm>
        </GridForm>
        <Grid span={10}>
          <DSSeparator
            type="non-form"
            margin="none"
            orientation="horizontal"
            position="initial"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default LoanInformationForm;

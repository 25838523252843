import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DSTextWrapper } from "@elliemae/ds-text-wrapper";
import { GridContainer, GridItem } from "@elliemae/ds-grid";
import { DSFormItemLayout, DSLargeInputText } from "@elliemae/ds-form";
import { useDispatch } from "react-redux";
import { setComments } from "data/lockSummary/actions";
import { useLockRequestComments } from "data/useSelectors";
import Session, { IS_BUYSIDE, IS_SELLSIDE } from "services/Session";
import { commentSectionMessage } from "./constants";
import { StyledCommentSectionContainer } from "./styled";

const CommentsSection = ({ isForPrint }) => {
  const dispatch = useDispatch();
  const requestComments = useLockRequestComments();
  const [value, setValue] = useState("");
  const isBuyside = Session.get(IS_BUYSIDE);
  const isSellside = Session.get(IS_SELLSIDE);

  useEffect(() => {
    setValue(requestComments);
  }, [requestComments]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = (e) => {
    const commentsValue = e.target.value.trim() || "";
    setValue(e.target.value);
    dispatch(setComments(commentsValue));
  };

  return (
    <StyledCommentSectionContainer style={{ breakInside: "avoid" }}>
      <GridContainer cols={2}>
        <GridItem span={1}>
          <DSFormItemLayout
            value={value}
            maxLength={2000}
            labelText="Comments"
            onChange={handleChange}
            feedbackMessage="Optional"
            characterLimitCounter={2000}
            inputComponent={DSLargeInputText}
            onBlur={handleBlur}
          />
        </GridItem>
        <GridItem span={2}>
          <DSTextWrapper
            containerProps={{ id: "lockSummary-textWrapper" }}
            text={!isBuyside && !isSellside && commentSectionMessage}
            style={
              isForPrint
                ? { color: "#8C93A6", fontSize: "11px" }
                : { marginBottom: "50px" }
            }
          />
        </GridItem>
      </GridContainer>
    </StyledCommentSectionContainer>
  );
};

CommentsSection.defaultProps = {
  isForPrint: false,
};

CommentsSection.propTypes = {
  isForPrint: PropTypes.bool,
};

export default CommentsSection;

import { safeSum, isValueNaN } from "utils/shared";
import { isCashOutRefinance } from "./validations";

export const isFirstMortgage = ({ customDependencies }) => {
  const { lienPosition } = customDependencies;
  return lienPosition === 1;
};

export const isSubordinateHELOC = ({ customDependencies }) => {
  const {
    lienPosition,
    initialOtherHELOCDraws,
    initialOtherHELOCLines,
  } = customDependencies;
  if (
    (!isValueNaN(initialOtherHELOCDraws) ||
      !isValueNaN(initialOtherHELOCLines)) &&
    lienPosition === 1
  ) {
    return false;
  }
  return lienPosition === 1;
};

export const isFirstMortgageAndHeloc = ({ customDependencies }) => {
  const { lienPosition, isHELOC } = customDependencies;
  return !(lienPosition === 1 && isHELOC);
};

export const isSecondMortgage = ({ customDependencies }) => {
  const { lienPosition } = customDependencies;
  return lienPosition === 2;
};

export const getLoanAmountLabel = ({ customDependencies }) => {
  const { lienPosition, isHELOC } = customDependencies;
  let label = "Loan Amount";
  if (lienPosition === 1 && isHELOC) {
    label = "Loan Amount (Initial Draw)";
  }
  if (lienPosition === 2) {
    label = "1st Mortgage Balance";
  }
  return label;
};

export const getPropertyValueLabel = ({ customDependencies }) => {
  const { lienPosition, loanPurpose } = customDependencies;
  let label = "Property Value";
  if (lienPosition === 1 && loanPurpose === 1) {
    label = "Purchase Price/Property Value";
  }
  return label;
};

export const getFloredValueTextInput = (val) => {
  const value = !["", null].includes(val) ? Math.floor(val) : "";
  return value === null || value === undefined ? "" : value;
};

export const calculateTotalLoanAmount = (
  firstMortgageAmount,
  financedAmount
) => {
  let result = 0;
  const ParsedLoanAmount = parseInt(firstMortgageAmount, 10) || 0;
  const ParsedFinancedAmount = parseInt(financedAmount, 10) || 0;
  result = safeSum(ParsedLoanAmount, Math.floor(ParsedFinancedAmount));
  return result;
};

export const handleTotalAmountValue = ({ customDependencies }) => {
  const { firstMortgageAmount, financedAmount } = customDependencies;
  const value = calculateTotalLoanAmount(firstMortgageAmount, financedAmount);
  return value;
};

export const isHCLTVHidden = ({ customDependencies }) => {
  const { isHELOC, lienPosition } = customDependencies;
  return !(isHELOC || lienPosition === 2);
};

export const getCheckboxesSpan = (customDependencies) => {
  const isHcltvHidden = isHCLTVHidden({ customDependencies });
  const isCashoutHidden = isCashOutRefinance({ customDependencies });
  if (isHcltvHidden && isCashoutHidden) return 8;
  if (isHcltvHidden || isCashoutHidden) return 7;
  return 6;
};

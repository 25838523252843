/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FooterButtons from "components/FooterButtons";
import { BUTTON_VARIANT } from "@elliemae/ds-button";
import { InfoCircle } from "@elliemae/ds-icons";
import { APPLY_EPPS_LOCKDESK_SETTINGS } from "common/constants/AppGlobals";
import Session, {
  APPLICATION_NAME,
  IS_LO,
  ALL_SUMMARY_PARAMS,
  IS_BUYSIDE,
} from "services/Session";
import Common from "common/services/Common";
import { isEmpty } from "utils/shared";
import {
  useLoanDataSelector,
  useRateSelector,
  useOriginalLoanInformationSelector,
  usePersonaAllowedActionsSelector,
  useSelectedProductSelector,
  useLockDeskStatusActionsSelector,
  useOriginalLoanLockRequests,
  useLoanDataFieldSelector,
  useLoanInformationField,
  useClientSettingsDataSelector,
} from "data/useSelectors";

import {
  saveLoanAction,
  requestLockAction,
} from "data/lockSummary/actionCreators";
import {
  setLockDeskModal,
  setLockRequestUnderOnrp,
} from "data/lockDeskStatus/actions";
import LockRequestedModal from "../LockRequestedModal";
import {
  isRequestLockButtonVisible,
  isSaveToLoanButtonVisible,
  isRequestLockButtonDisabled,
  isSaveToLoanButtonDisabled,
  getRequestLockActionType,
  getEPCActionType,
  checkLockRequested,
  isFloatButtonVisible,
  isFloatButtonDisabled,
} from "./utils";
const FooterActionButtons = () => {
  const dispatch = useDispatch();
  const loanData = useLoanDataSelector();
  const rateSelector = useRateSelector();
  const clientDataSettingsSelector = useClientSettingsDataSelector();
  const allowedActions = usePersonaAllowedActionsSelector();
  const {
    isDisableRequestLockButton,
    isAllowLockUpdate,
  } = useLockDeskStatusActionsSelector();
  const applicationName = Session.get(APPLICATION_NAME);
  const {
    loan: { noClosingCost, lenderFeeWaiver, autolocked } = {},
  } = rateSelector;
  const { tpo } = useOriginalLoanInformationSelector();
  const { rateStale } = useSelectedProductSelector();
  const lockRequests = useOriginalLoanLockRequests();
  const [isRequestedModalOpen, setRequestedModalOpen] = useState(false);
  const firstMortgageAmount = useLoanInformationField("firstMortgageAmount", 0);
  const loanChannel = useLoanDataFieldSelector("loanInformation.loanChannel");
  const isValidChannel = [0, 1, 2, 4].includes(loanChannel);
  const isBuySide = Session.get(IS_BUYSIDE);
  const { relockRequest } = loanData;
  const { specificProgramID } = relockRequest;
  const isVisibleLockDesk = isValidChannel && !isBuySide;

  const handleSaveLoanAction = async () => {
    await dispatch(
      saveLoanAction({
        noClosingCost,
        lenderFeeWaiver,
      })
    );
  };

  const handleFloatAction = () => {};

  const shutDownAllowLockRequestValidation = () => {
    let isLockRequestDisable = false;
    if (isVisibleLockDesk && isAllowLockUpdate) {
      if (specificProgramID === null) {
        isLockRequestDisable = true;
      }
      if (isEmpty(Session.get(ALL_SUMMARY_PARAMS))) {
        return isLockRequestDisable;
      }
      const selectedProductData = Session.get(ALL_SUMMARY_PARAMS);
      const { programId } = selectedProductData || {};
      if (specificProgramID !== programId) {
        isLockRequestDisable = true;
      }
    }
    return isLockRequestDisable;
  };

  const handleRequestLockAction = async () => {
    const requestLockActionType = getRequestLockActionType(loanData);
    const EPCActionType = getEPCActionType(requestLockActionType, autolocked);
    setRequestedModalOpen(false);
    await dispatch(
      requestLockAction(EPCActionType, requestLockActionType, {
        noClosingCost,
        lenderFeeWaiver,
      })
    );
  };

  const fetchLockDeskValidateStatus = async () => {
    let isEligible = true;
    const { programId } = Session.get(ALL_SUMMARY_PARAMS) || {};
    const { requestLockStatus, requestAction } = loanData;
    const currentChannel = loanChannel === 0 ? 1 : loanChannel;
    try {
      if (isVisibleLockDesk) {
        const response = await Common.getLockDeskValidateData({
          loanChannelId: currentChannel,
          loanAmount: firstMortgageAmount,
          previousProgramId: specificProgramID,
          currentProgramId: programId,
          requestActionType: requestAction,
          requestLockStatus,
        });
        isEligible = response.eligible;
        if (response?.isLockRequestUnderOnrp) {
          dispatch(setLockRequestUnderOnrp(true));
        }
      }
    } catch (error) {
      console.log(error);
    }
    return isEligible;
  };

  const handleRequestLockClick = async () => {
    if (APPLY_EPPS_LOCKDESK_SETTINGS) {
      if (await fetchLockDeskValidateStatus()) {
        const isLockRequested = checkLockRequested(loanData, lockRequests);
        if (isLockRequested) setRequestedModalOpen(true);
        else handleRequestLockAction();
      }
    } else {
      const isLockRequested = checkLockRequested(loanData, lockRequests);
      if (isLockRequested) setRequestedModalOpen(true);
      else handleRequestLockAction();
    }
  };

  const requestLockButtonDisabled = isRequestLockButtonDisabled(
    rateStale,
    loanData,
    applicationName,
    allowedActions,
    isDisableRequestLockButton,
    shutDownAllowLockRequestValidation
  );

  const floatButtonVisible = isFloatButtonVisible(clientDataSettingsSelector);
  const floatButtonDisabled = isFloatButtonDisabled();

  const saveToLoanButtonDisabled = isSaveToLoanButtonDisabled(
    loanData,
    applicationName,
    allowedActions
  );

  const saveToLoanButtonVisible = isSaveToLoanButtonVisible(
    applicationName,
    allowedActions,
    tpo
  );

  const requestLockButtonVisible = isRequestLockButtonVisible(
    applicationName,
    allowedActions
  );

  return (
    Session.get(IS_LO) && (
      <>
        <LockRequestedModal
          isModalOpen={isRequestedModalOpen}
          handleRequest={handleRequestLockAction}
          handleCancel={() => setRequestedModalOpen(false)}
        />
        <FooterButtons
          data-testid="FooterActionButtonsLock-all-components"
          buttons={[
            {
              type: "secondary",
              text: "Float",
              onClick: handleFloatAction,
              variant: BUTTON_VARIANT.DEFAULT,
              visible: floatButtonVisible,
              disabled: floatButtonDisabled,
            },
            {
              type: "secondary",
              text: "Update Lock Request Form",
              onClick: handleSaveLoanAction,
              variant: BUTTON_VARIANT.DEFAULT,
              disabled: saveToLoanButtonDisabled,
              visible: saveToLoanButtonVisible,
            },
            {
              type: "primary",
              text: "Request Lock",
              onClick: handleRequestLockClick,
              variant: requestLockButtonDisabled
                ? BUTTON_VARIANT.DISABLED
                : BUTTON_VARIANT.DEFAULT,
              disabled: requestLockButtonDisabled,
              visible: requestLockButtonVisible,
            },
          ]}
          tooltip={
            isVisibleLockDesk && (
              <InfoCircle
                color={["brand-primary", "600"]}
                onClick={() => dispatch(setLockDeskModal(true))}
              />
            )
          }
        />
      </>
    )
  );
};

export default FooterActionButtons;
